exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cancellation-policy-tsx": () => import("./../../../src/pages/cancellation-policy.tsx" /* webpackChunkName: "component---src-pages-cancellation-policy-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-information-about-the-driver-tsx": () => import("./../../../src/pages/information-about-the-driver.tsx" /* webpackChunkName: "component---src-pages-information-about-the-driver-tsx" */),
  "component---src-pages-insurance-packages-tsx": () => import("./../../../src/pages/insurance-packages.tsx" /* webpackChunkName: "component---src-pages-insurance-packages-tsx" */),
  "component---src-pages-paros-tsx": () => import("./../../../src/pages/paros.tsx" /* webpackChunkName: "component---src-pages-paros-tsx" */),
  "component---src-pages-payment-methods-tsx": () => import("./../../../src/pages/payment-methods.tsx" /* webpackChunkName: "component---src-pages-payment-methods-tsx" */),
  "component---src-pages-rental-terms-tsx": () => import("./../../../src/pages/rental-terms.tsx" /* webpackChunkName: "component---src-pages-rental-terms-tsx" */),
  "component---src-pages-transfers-tsx": () => import("./../../../src/pages/transfers.tsx" /* webpackChunkName: "component---src-pages-transfers-tsx" */),
  "component---src-pages-vehicle-rental-tips-tsx": () => import("./../../../src/pages/vehicle-rental-tips.tsx" /* webpackChunkName: "component---src-pages-vehicle-rental-tips-tsx" */),
  "component---src-pages-vehicles-tsx": () => import("./../../../src/pages/vehicles.tsx" /* webpackChunkName: "component---src-pages-vehicles-tsx" */)
}

